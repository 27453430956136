<template>
    <v-card class="mb-6" v-if="order">
        <v-card-title>Общая информация</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="4" class="py-2">Номер заказа</v-col>
                <v-col cols="8" class="py-2">{{ order.external_id }}</v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="py-2">Дата</v-col>
                <v-col cols="8" class="py-2">{{ order.created_at | moment('DD.MM.YYYY HH:mm') }}</v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="py-2">Статус</v-col>
                <v-col cols="8" class="py-2 d-flex justify-space-between align-center">
                    <span class="true-false-label primary" :style="`background: ${ statuses.orders[order.status].color } !important;`">
                        {{ statuses.orders[order.status].title }}
                    </span>
                    <v-menu offset-y left>
                        <template #activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <v-icon color="primary">mdi-pencil</v-icon>
                            </div>
                        </template>
                        <v-list>
                            <template v-for="(status, index) of sortedStatusesOrders">
                                <v-list-item :key="`status-${ index }`" @click="setStatus(status.code)">
                                    <v-list-item-title :style="`color: ${ status.color }`">{{ status.title }}</v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="py-2">Ресторан</v-col>
                <v-col cols="8" class="py-2">
                    <a href="#" @click.prevent="$router.push({ path: `/sites/${ order.site.id }/detail` })">{{ order.site.title }}</a>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="py-2">Точка продаж</v-col>
                <v-col cols="8" class="py-2">
                    <a href="#" @click.prevent="$router.push({ path: `/sites/${ order.site.id }/entities/${ order.entity.id }/detail` })">{{ order.entity.title }}</a>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="py-2">Пользователь</v-col>
                <v-col cols="8" class="py-2">
                    <template v-if="user.admin">
                        <a href="#" @click.prevent="$router.push({ path: `/users/${ order.user.id }/detail` })">{{ order.user.firstname }} {{ order.user.lastname }} {{ order.user.phone }}</a>
                    </template>
                    <template v-else>
                        {{ order.user.firstname }} {{ order.user.lastname }} {{ order.user.phone }}
                    </template>
                </v-col>
            </v-row>
            <v-row v-if="order.additional_data.comment">
                <v-col cols="4" class="py-2">Комментарий</v-col>
                <v-col cols="8" class="py-2">
                    <b>{{ order.additional_data.comment }}</b>
                </v-col>
            </v-row>
            <v-row v-if="order.payment">
                <v-col cols="4" class="py-2">Тип оплаты</v-col>
                <v-col cols="8" class="py-2">
                    {{ order.payment.title }}
                </v-col>
            </v-row>
            <v-row v-if="order.delivery">
                <v-col cols="4" class="py-2">Тип доставки</v-col>
                <v-col cols="8" class="py-2">
                    {{ order.delivery.title }}
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="py-2">Адрес доставки</v-col>
                <v-col cols="8" class="py-2">
                    {{ fullAddressFormat(order.user_address) }}
                </v-col>
            </v-row>
            <v-row v-if="order.delivery">
                <v-col cols="4" class="py-2">Стоимость доставки</v-col>
                <v-col cols="8" class="py-2">
                    {{ order.delivery.price_by_agreement ? 'По согласованию' : priceFormated(order.delivery_price) }}
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="py-2">Итоговая стоимость заказа</v-col>
                <v-col cols="8" class="py-2">{{ priceFormated(order.full_price) }}</v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import store from '../../store';
    import { mapState } from 'vuex';
    
    import { priceFormated } from '../../helpers';
    import { fullAddressFormat } from '../../helpers/user';

    export default {
        props: {
            statuses: {
                type: Object,
                default: () => {}
            },
            order: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                priceFormated,
                fullAddressFormat,
                claimError: null,
                claimDuration: 8,
                claimRemainingTime: '',
                claimTimerId: null
            }
        },
        computed: {
            ...mapState('auth', ['user']),
            sortedStatusesOrders(){
                const statuses = [];
                for(const key in this.statuses.orders) {
                    statuses.push({
                        code: key,
                        ...this.statuses.orders[key]
                    });
                }
                return statuses.sort((a, b) => a.sort - b.sort);
            },
        },
        mounted() {
        },
        beforeDestroy() {
        },
        methods: {
            async setStatus(status) {
                await store.dispatch('orders/setStatus', { id: this.order.id, status });
            },
        }
    }
</script>
