<template>
    <v-card v-if="order && order.entity_setting && order.entity_setting.yandex_delivery && order.entity_setting.yandex_delivery.token">
        <v-card-title>Яндекс доставка</v-card-title>
        <v-card-text>
            <v-row v-if="!filledYandexDeliverySettings">
                <v-col>
                    <v-alert outlined type="error" class="ma-0">
                        Для успешного вызова курьеров, заполните все поля для Яндекс доставки в разделе "<a @click="goToSettings">Настройки</a>".
                    </v-alert>
                </v-col>
            </v-row>
            <template v-else>
                <template v-if="order.delivery_claim">
                    <v-row v-if="order.delivery_claim && order.delivery_claim.status === 'ready_for_approval' && claimRemainingTime">
                        <v-col>
                            <v-alert outlined type="info" class="ma-0">
                                У вас осталось <b>{{ claimRemainingTime }}</b> чтобы подтвердить или отменить заявку. 
                                В противном случае она автоматически отменится и стоимость необходимо будет запрашивать заново.
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row v-if="order.delivery_claim.external_id">
                        <v-col cols="4" class="py-1">ID заявки</v-col>
                        <v-col cols="8" class="py-1"><i>{{ order.delivery_claim.external_id }}</i></v-col>
                    </v-row>
                    <v-row v-if="order.delivery_claim.status_description">
                        <v-col cols="4" class="py-1">Статус</v-col>
                        <v-col cols="8" class="py-1"><i>{{ order.delivery_claim.status_description }}</i></v-col>
                    </v-row>
                    <v-row v-if="order.delivery_claim.price">
                        <v-col cols="4" class="py-1">Стоимость</v-col>
                        <v-col cols="8" class="py-1"><b>{{ priceFormated(order.delivery_claim.price) }}</b></v-col>
                    </v-row>
                    <v-row v-if="order.delivery_claim.etd">
                        <v-col cols="4" class="py-1">Ожидаемое время прибытия курьера</v-col>
                        <v-col cols="8" class="py-1"><b>{{ order.delivery_claim.etd | moment('DD.MM.YYYY HH:mm') }}</b></v-col>
                    </v-row>
                    <v-row v-if="order.delivery_claim.eta">
                        <v-col cols="4" class="py-1">Ожидаемое время доставки</v-col>
                        <v-col cols="8" class="py-1"><b>{{ order.delivery_claim.etd | moment('DD.MM.YYYY HH:mm') }}</b></v-col>
                    </v-row>

                    <v-row v-if="order.delivery_claim.status === 'ready_for_approval'">
                        <v-col class="d-flex justify-space-between flex-wrap">
                            <v-btn class="mt-2" color="success" @click="acceptDeliveryClaim">Подтвердить заявку</v-btn>
                            <v-btn class="mt-2" color="error" @click="cancelDeliveryClaim">Отменить заявку</v-btn>
                        </v-col>
                    </v-row>
                </template>
                <v-row v-if="!order.delivery_claim || !!order.delivery_claim.finished">
                    <v-col>
                        <v-btn color="primary" @click="createDeliveryClaim">Создать новую заявку</v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="claimError">
                    <v-col>
                        <v-alert dense text type="error" class="ma-0">
                            {{ claimError }}
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
    import store from '../../store';
    import moment from '../../plugins/moment';
    import { priceFormated } from '../../helpers';

    export default {
        props: {
            order: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                priceFormated,
                claimError: null,
                claimDuration: 8,
                claimRemainingTime: '',
                claimTimerId: null
            }
        },
        computed: {
            filledYandexDeliverySettings() {
                return (
                    this.order.entity_setting.yandex_delivery.token && 
                    this.order.entity_setting.yandex_delivery.address && 
                    this.order.entity_setting.yandex_delivery.coordinates && 
                    this.order.entity_setting.yandex_delivery.coordinates[0] && 
                    this.order.entity_setting.yandex_delivery.coordinates[1] && 
                    this.order.entity_setting.yandex_delivery.contact_name && 
                    this.order.entity_setting.yandex_delivery.contact_phone
                );
            },
        },
        mounted() {
            if(this.order.delivery_claim) {
                this.startClaimTimer();
            }
        },
        beforeDestroy() {
            clearInterval(this.claimTimerId);
        },
        methods: {
            async checkDeliveryClaim() {
                await store.dispatch('orders/checkDeliveryClaim', { id: this.order.id });
            },
            async createDeliveryClaim() {
                this.claimError = null;
                const data = await store.dispatch('orders/createDeliveryClaim', { id: this.order.id });
                if(data.error) {
                    this.claimError = data.status === 401 ? 'Указан неверный токен. Получите правильный токен в личном кабинете.' : data.error;
                } else {
                    this.startClaimTimer();
                }
            },
            async acceptDeliveryClaim() {
                this.claimError = null;
                const data = await store.dispatch('orders/acceptDeliveryClaim', { id: this.order.id });
                if(data.error) {
                    this.claimError =  data.status === 401 ? 'Указан неверный токен. Получите правильный токен в личном кабинете.' : data.error;
                } else {
                    clearInterval(this.claimTimerId);
                    this.$emit('order-fetch');
                }
            },
            async cancelDeliveryClaim() {
                this.claimError = null;
                const data = await store.dispatch('orders/cancelDeliveryClaim', { id: this.order.id });
                if(data.error) {
                    this.claimError =  data.status === 401 ? 'Указан неверный токен. Получите правильный токен в личном кабинете.' : data.error;
                } else {
                    clearInterval(this.claimTimerId);
                    this.$emit('order-fetch');
                }
            },
            startClaimTimer() {
                this.claimTimerId = setInterval(async () => {
                    const diffSeconds = moment().diff(moment(this.order.delivery_claim.created_at), 'seconds');
                    const remainingSeconds = this.claimDuration * 60 - diffSeconds;
                    const remainingTime = moment.duration(remainingSeconds, 'seconds');
                    this.claimRemainingTime = `0${remainingTime.minutes()}:${remainingTime.seconds() < 10 ? '0' : ''}${remainingTime.seconds()}`;
                    if (moment().diff(this.order.delivery_claim.created_at, 'minutes') >= this.claimDuration) {
                        clearInterval(this.claimTimerId);
                        this.$emit('order-fetch');
                    }
                }, 1000);
            },
            goToSettings() {
                this.$router.push(`/entities/${this.order.entity_id}/setting`);
            },
        }
    }
</script>
