<template>
    <div>
        <v-row>
            <v-col cols="12" md="6" v-if="order.id">
                <MainInfo 
                    :statuses="statuses"
                    :order="order"
                />
                <DeliveryClaim 
                    :order="order"
                    @order-fetch="fetch"
                />
            </v-col>
            <v-col cols="12" md="6">
                <v-data-table
                    :headers="paymentHeaders"
                    :items="order.payments"
                    class="elevation-1 mb-6"
                    disable-sort
                    disable-pagination
                    hide-default-footer
                    mobile-breakpoint="0"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Платежи</v-toolbar-title>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                        {{ item.created_at | moment('DD.MM.YYYY HH:mm') }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <span class="true-false-label primary" :style="`background: ${ statuses.payments[item.status].color } !important;`">
                            {{ statuses.payments[item.status].title }}
                        </span>
                    </template>
                    <template v-slot:[`item.amount`]="{ item }">
                        {{ priceFormated(item.amount) }}
                    </template>
                </v-data-table>

                <v-data-table
                    :headers="statusHeaders"
                    :items="order.status_change"
                    class="elevation-1"
                    disable-sort
                    disable-pagination
                    hide-default-footer
                    mobile-breakpoint="0"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>История статусов</v-toolbar-title>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.change_at`]="{ item }">
                        {{ item.change_at | moment('DD.MM.YYYY HH:mm') }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <span class="true-false-label primary" :style="`background: ${ statuses.orders[item.status].color } !important;`">
                            {{ statuses.orders[item.status].title }}
                        </span>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row v-if="order.products && order.products.length">
            <v-col>
                <v-data-table
                    :headers="productHeaders"
                    :items="productsWithSummary"
                    class="elevation-1"
                    disable-sort
                    disable-pagination
                    hide-default-footer
                    mobile-breakpoint="0"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Продукты</v-toolbar-title>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.title`]="{ item }">
                        <span class="product-title">{{ item.product.title }}</span>
                    </template>
                    <template v-slot:[`item.options`]="{ item }">
                        {{ options(item) }}
                    </template>
                    <template v-slot:[`item.full_price`]="{ item }">
                        <nobr v-if="item.full_price">{{ priceFormated(getProductPrice(item.product)) }}</nobr>
                    </template>
                    <template v-slot:[`item.result_price`]="{ item }">
                        <nobr v-if="item.full_order_price">{{ priceFormated(item.full_order_price) }}</nobr>
                        <nobr v-else-if="item.full_price && item.quantity">{{ priceFormated(item.full_price * item.quantity) }}</nobr>
                    </template>
                    <template v-slot:[`item.discount`]="{ item }">
                        <div class="d-flex justify-start align-center" v-if="item.product.discount && item.product.discount.type === DISCOUNT_TYPE.PERCENT">
                            <span>-{{ item.product.discount.percent }}%</span>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                    >mdi-help-circle-outline</v-icon>
                                </template>
                                <span>{{ item.product.discount.title }}</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import store from '../../store';
    import { mapState } from 'vuex';

    import MainInfo from '../../components/orders/MainInfo';
    import DeliveryClaim from '../../components/orders/DeliveryClaim';

    import { DISCOUNT_TYPE } from '../../vars';
    import { imageSrc, priceFormated } from '../../helpers';
    

    export default {
        name: 'OrderDetail',

        components: {
            MainInfo,
            DeliveryClaim
        },

        data() {
            return {
                DISCOUNT_TYPE,
                imageSrc,
                priceFormated,
                deleteModal: false,
                statusHeaders: [
                    { text: 'Дата', value: 'change_at', sortable: true, filterable: false },
                    { text: 'Статус', value: 'status', sortable: false, filterable: false },
                    { text: 'Изменил', value: 'change_by', sortable: false, filterable: false },
                ],
                productHeaders: [
                    { text: 'Название', value: 'title', sortable: true, filterable: false },
                    { text: 'Опции', value: 'options', sortable: false, filterable: false },
                    { text: 'Стоимость', value: 'full_price', sortable: true, filterable: false },
                    { text: 'Количество', value: 'quantity', sortable: true, filterable: false },
                    { text: 'Скидка', value: 'discount', sortable: true, filterable: false },
                    { text: 'Сумма', value: 'result_price', sortable: false, filterable: false },
                ],
                paymentHeaders: [
                    { text: 'Дата', value: 'created_at', sortable: true, filterable: false },
                    { text: 'Статус', value: 'status', sortable: false, filterable: false },
                    { text: 'Сумма', value: 'amount', sortable: false, filterable: false },
                ],
                updateTimerId: null,
            };
        },

        computed: {
            ...mapState('orders', {
                statuses: state => state.statuses,
                order: state => state.entity
            }),
            productsWithSummary() {
                if(this.order) {
                    const products = [...this.order.products];
                    if(this.order.additional_data && this.order.additional_data.cutlery) {
                        products.push({ product: { title: 'Приборы' }, quantity: this.order.additional_data.cutlery });
                    }
                    products.push({ product: { title: 'Итого' }, full_order_price: this.order.products.reduce((sum, item) => {
                        return sum + (item.quantity * item.full_price);
                    }, 0) });
                    return products;
                }
                return [];
            }
        },

        async mounted() {
            await store.dispatch('orders/statuses');
            await this.fetch();
            this.updateTimerId = setInterval(this.fetch, 10000);
        },
        beforeDestroy() {
            clearInterval(this.updateTimerId);
        },

        methods: {
            async fetch() {
                const id = this.$route.params.id;
                if(id) {
                    await store.dispatch('orders/get', {id});
                }
            },
            getProductPrice(product) {
                return product.price + (product.options ? product.options.reduce((result, item) => {
                    result += +item.price;
                    return result;
                }, 0) : 0);
            },
            options(product) {
                if(product.options && product.options.length) {
                    return product.options.map(item => item.option.title).join(' • ');
                }
                return '';
            },
            goBack() {
                this.$router.push({ path: '/orders' });
            },
        },
    };
</script>

<style scoped>
    .product-title {
        font-size: 16px !important;
        line-height: 18px;
        font-weight: 700;
    }
</style>